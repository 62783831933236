
/**
 * {
    name: 'phone',
    icon: 'mdi-cellphone-check',
    text: '绑定手机号'
  },
  {
    name: 'person',
    icon: 'mdi-account-cog-outline',
    text: '个人基本资料'
  }, {
    name: 'hospital',
    icon: 'mdi-hospital-box-outline',
    text: '单位/学校信息'
  }, {
    name: 'password',
    icon: 'mdi-form-textbox-password',
    text: '修改密码'
  },
 */

import api from '@/api/co.api'
import app from '@/api/co.app'
import user from '@/api/co.user'

export default {
  mixins: [],
  data () {
    return {
      info: {},
      btnShare: {
        icon: 'mdi-share',
        text: '分享'
      },
      btnInfo: {
        text: '完善信息',
        color: 'primary',
        items: [{
          name: 'account',
          icon: 'mdi-account-circle-outline',
          text: '账号信息设置'
        }, {
          name: 'logout',
          icon: 'mdi-logout',
          text: '退出账号'
        }]
      },
      handlers: {
        account: this.toUserInfo,
        person: this.toUserInfo,
        hospital: this.toUserInfo,
        phone: this.toUserInfo,
        password: this.toUserInfo,
        logout: this.toLogout
      },
      alert: {
        visible: false,
        name: 'altName',
        timeout: 2000,
        color: 'blue-grey',
        content: ''
      }
    }
  },
  created () {
    this.info = user.info.get()
  },
  methods: {
    toInfo () {
      const params = {
        name: 'account'
      }

      api.page.navigate(this, 'info', params)
    },
    toUserInfo (item) {
      const params = {
        name: item.name
      }

      api.page.navigate(this, 'info', params)
    },
    toLogout (item) {
      const me = this
      const executing = function () {
        me.loading = true
      }
      const executed = function (res) {
        me.loading = false
        if (res.status) {
          app.emit(app.event.USER_LOGOUT)
          me.$notify({
            title: '成功提示',
            message: '账号退出成功！',
            type: 'success'
          })
        } else {
          me.$notify({
            title: '错误提示',
            message: '账号退出失败！',
            type: 'error'
          })
        }
      }

      user.login.exit({
        executing,
        executed
      })
    },
    onInfoAction (ev) {
      const item = ev.item
      const handler = this.handlers[item.name]
      if (typeof handler === 'function') {
        handler(item)
      }
    }
  }
}
