<template>
  <section>
    <user-banner
      ref="userBanner"
      @action="onSetCover"
    />
    <land-section
      id="my_center_info"
      space="4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <div class="pa-5" />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div
              class="co-flex-col co-justify-center co-items-center co-w-full px-4 pt-4"
            >
              <!-- <v-badge
                avatar
                overlap
                bottom
                :value="info.badge !== ''"
              >
                <template #badge>
                  <v-avatar>
                    <v-img
                      :src="info.badge"
                    />
                  </v-avatar>
                </template>
                <v-avatar
                  size="96"
                >
                  <v-img
                    :src="info.avatar || defAvatar"
                  />
                </v-avatar>
              </v-badge> -->

              <v-avatar
                size="96"
              >
                <v-img
                  :src="info.avatar"
                />
              </v-avatar>

              <div class="co-flex-row co-items-center">
                <span class="text-h5 my-2">
                  {{ info.username }}
                </span>
                <!-- <v-avatar
                  v-if="info.badge"
                  size="24"
                  class="ml-1"
                >
                  <v-img
                    :src="info.badge"
                  />
                </v-avatar> -->
              </div>

              <span
                v-if="info.organizationName"
                class="text-body-1 primary--text"
              >
                {{ info.organizationName }}
              </span>
              <span class="text-caption my-3">
                {{ `IP属地：${info.ipBelong || '未知'}` }}
              </span>
              <div class="co-flex-row">
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipHot.label }}
                  <span class="pl-1">{{ tipHot.count }}</span>
                </span>
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipFans.label }}
                  <span class="pl-1">{{ tipFans.count }}</span>
                </span>
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipAttention.label }}
                  <span class="pl-1">{{ tipAttention.count }}</span>
                </span>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <div class="co-flex-row co-justify-end mt-8">
              <v-btn
                outlined
                @click="toShowQrcode"
              >
                <v-icon
                  v-if="btnShare.icon"
                  left
                >
                  {{ btnShare.icon }}
                </v-icon>
                {{ btnShare.text }}
              </v-btn>

              <div class="co-w12 co-h16" />
              <v-btn
                color="primary"
                @click="toInfo"
              >
                完善信息
              </v-btn>
              <!-- <pop-menu
                :iconed="false"
                :texted="false"
                :text="btnInfo.text"
                :color="btnInfo.color"
                :actions="btnInfo.items"
                @action="onInfoAction"
              /> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <v-divider />
    <land-section
      id="my_center_tabs"
      space="4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-card
          color="transparent"
          flat
          tile
        >
          <v-tabs
            v-model="currTab"
            background-color="transparent"
            class="d-flex flex-row justify-center align-center"
          >
            <v-tab
              v-for="(tab, tabIndex) in tabList"
              :key="tabIndex"
              :value="tab.name"
              :href="`#${tab.name}`"
              class="text-h6"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-container>
    </land-section>
    <land-section
      id="my_center_items"
      space="20"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 1800px;"
      >
        <div
          v-if="currTab !== 'activitys'"
          class="co-flex-row co-justify-end co-w-full px-4 py-4"
        >
          <pop-menu
            :iconed="false"
            :text="scope.text"
            :prefix-icon="scope.icon"
            :actions="scope.items"
            @action="changeScope"
          />
        </div>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-tabs-items v-model="currTab">
              <v-tab-item
                value="works"
                class="grey lighten-4"
              >
                <my-works ref="my_works" />
              </v-tab-item>
              <v-tab-item
                value="albums"
                class="grey lighten-4"
              >
                <my-albums ref="my_albums" />
              </v-tab-item>
              <v-tab-item
                value="videos"
                class="grey lighten-4"
              >
                <my-videos ref="my_videos" />
              </v-tab-item>
              <v-tab-item
                value="circles"
                class="grey lighten-4"
              >
                <my-works />
              </v-tab-item>
              <v-tab-item
                value="articles"
                class="grey lighten-4"
              >
                <my-articles ref="my_articles" />
              </v-tab-item>
              <v-tab-item
                value="activitys"
                class="grey lighten-4"
              >
                <my-enrolls ref="my_activitys" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <dialog-covers ref="dlgCover" />
    <dialog-my-qrcode
      ref="dlgQrcode"
      :user-info="userInfo"
    />
  </section>
</template>

<script>
  import app from '@/api/co.app'

  import mixMyBanner from '@/pages/mixins/my/mix.my.banner'
  import mixMyBoot from '@/pages/mixins/my/mix.my.boot'
  import mixMyInfo from '@/pages/mixins/my/mix.my.info'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
      DialogCovers: () => import('@/pages/sections/comm/DialogCovers.vue'),
      DialogMyQrcode: () => import('@/pages/sections/comm/DialogMyQrcode.vue'),
      UserBanner: () => import('@/pages/sections/my/UserBanner.vue'),
      MyWorks: () => import('@/pages/sections/my/MyWorks.vue'),
      MyAlbums: () => import('@/pages/sections/my/MyAlbums.vue'),
      MyVideos: () => import('@/pages/sections/my/MyVideos.vue'),
      MyArticles: () => import('@/pages/sections/my/MyArticles.vue'),
      MyEnrolls: () => import('@/pages/sections/my/MyEnrolls.vue')
    },
    mixins: [
      mixMyBanner,
      mixMyBoot,
      mixMyInfo
    ],
    data () {
      return {
        drawer: false,
        pageTitle: '个人中心',
        defAvatar: app.pic.avatar_user,
        myQrcode: {
          visibled: false,
          size: 200,
          text: '',
          logo: require('@/assets/logo.png'),
          logoScale: 0.2
        }
      }
    },
    created () {
      app.on(app.event.MY_WORKS_GET, this.onMyContentsGet)
      app.on(app.event.MY_ALBUMS_GET, this.onMyContentsGet)
      app.on(app.event.MY_VIDEOS_GET, this.onMyContentsGet)
      app.on(app.event.MY_ARTICLES_GET, this.onMyContentsGet)
      app.on(app.event.MY_ACTIVITYS_GET, this.onMyContentsGet)
      app.on(app.event.USER_COVER_CHANGE, this.changeCover)

      this.checkCount()
    },
    destroyed () {
      app.off(app.event.USER_COVER_CHANGE)
      app.off(app.event.MY_WORKS_GET)
      app.off(app.event.MY_ALBUMS_GET)
      app.off(app.event.MY_VIDEOS_GET)
      app.off(app.event.MY_ARTICLES_GET)
      app.off(app.event.MY_ACTIVITYS_GET)
    },
    methods: {
      toShowQrcode () {
        const frm = this.$refs.dlgQrcode
        if (frm) {
          frm.show()
        }
      },
      changeCover (ev) {
        const frm = this.$refs['userBanner']
        if (frm) {
          frm.reset(ev)
        }
      },
      onSetCover (ev) {
        const frm = this.$refs['dlgCover']
        if (frm) {
          frm.show()
        }
      },
      onMyContentsGet (ev) {
        // console.log('onMyContentsGet ev: %o', ev)
        const part = this.getPart(ev.name)
        if (part) {
          const title = ev.total ? ev.title : ev.label
          this.$set(part, 'title', title)
        }
      },
    }
  }
</script>
