
import api from '@/api/co.api'
import app from '@/api/co.app'
import user from '@/api/co.user'
import web from '@/api/web/co.web'
import mixPreset from '@/pages/mixins/my/mix.my.preset'

const parts = mixPreset.Parts
const names = mixPreset.Names

export default {
  data () {
    return {
      userInfo: {},
      currTab: names.WORKS,
      tabList: [
        parts[names.WORKS],
        parts[names.VIDEOS],
        parts[names.ARTICLES],
        parts[names.ACTIVITYS],
        parts[names.ALBUMS],
      ],
      scope: {
        text: '',
        value: '',
        icon: '',
        tip: '选择作品隶属范围',
        items: []
      },
      tipHot: {
        label: '热度',
        count: 0
      },
      tipAttention: {
        label: '关注',
        count: 0
      },
      tipFans: {
        label: '粉丝',
        count: 0
      },
      handlers: {
        [names.WORKS]: this.checkContentCount,
        [names.ALBUMS]: this.checkAlbumsCount,
        [names.VIDEOS]: this.checkContentCount,
        [names.ARTICLES]: this.checkArticlesCount,
        [names.ACTIVITYS]: this.checkActivitysCount,
      }
    }
  },
  created () {
    this.userInfo = user.info.get()
    this.getExtendInfo()
    this.configScope()
  },
  methods: {
    getPart (name) {
      let result = null
      for (const i in this.tabList) {
        const item = this.tabList[i]
        if (item.name === name) {
          result = item
          break
        }
      }
      return result
    },
    getExtendInfo () {
      const me = this
      const executed = function (res) {
        // console.log({ res })
        if (res.status) {
          const data = res.data || {}
          me.tipAttention.count = data.toFansNum || 0
          me.tipFans.count = data.fansNum || 0
          me.tipHot.count = data.hotNum || 0
        }
      }

      user.info.extend({
        executed
      })
    },
    configScope () {
      this.scope.items = []
      const descs = web.comm.ScopeDescs
      for (const key in descs) {
        const item = descs[key]
        this.scope.items.push(item)
      }
      const obj = this.scope.items[0]
      this.scope.text = obj.text
      this.scope.value = obj.value
      this.scope.icon = obj.icon
    },
    changeScope (ev) {
      const item = ev.item
      const refName = `my_${this.currTab}`
      this.scope.text = item.text
      this.scope.value = item.value
      this.scope.icon = item.icon

      const wd = this.$refs[refName]
      // console.log('name: %o, wd: %o', refName, wd)
      if (wd) {
        wd.setScope(item.value)
      }
    },
    checkContentCount (part) {
      const executed = function (res) {
        if (res.status) {
          part.title = `${part.label} ${res.total}`
          app.emit(app.event.MY_WORKS_GET, {
            name: part.name,
            title: part.title,
            label: part.label,
            total: res.total
          })
        }
      }

      const params = web.content.getParamsPersonal({
        type: part.type,
        accessScope: web.comm.Scopes.PUBLIC,
        pageSize: '1',
        executed
      })
      params.reload = true
      api.httpx.getItems(params)
    },
    checkArticlesCount (part) {
      const executed = function (res) {
        if (res.status) {
          part.title = `${part.label} ${res.total}`
          app.emit(app.event.MY_WORKS_GET, {
            name: part.name,
            title: part.title,
            label: part.label,
            total: res.total
          })
        }
      }

      const params = web.content.getParamsPersonal({
        type: part.type,
        accessScope: web.comm.Scopes.PUBLIC,
        state: web.comm.States.PUBLISH,
        pageSize: '1',
        executed
      })
      params.reload = true
      api.httpx.getItems(params)
    },
    checkActivitysCount (part) {
      const executed = function (res) {
        if (res.status) {
          part.title = `${part.label} ${res.total}`
          app.emit(app.event.MY_ACTIVITYS_GET, {
            name: part.name,
            title: part.title,
            label: part.label,
            total: res.total
          })
        }
      }

      const params = web.activityEnroll.getParams({
        createBy: this.userInfo.userId,
        pageSize: '1',
        executed
      })
      params.reload = true
      api.httpx.getItems(params)
    },
    checkAlbumsCount (part) {
      const executed = function (res) {
        // console.log({ res })
        if (res.status) {
          const data = res.data || {}
          part.title = `${part.label} ${data.total}`
          app.emit(app.event.MY_ALBUMS_GET, {
            name: part.name,
            title: part.title,
            label: part.label,
            total: data.total
          })
        }
      }

      api.httpx.pull({
        url: api.url.web_album_albums_personal,
        data: {
          currentPage: 1,
          pageSize: 1,
          accessScope: web.comm.Scopes.PUBLIC
        },
        executed
      })
    },
    checkCount () {
      for (const i in this.tabList) {
        const part = this.tabList[i]
        const handler = this.handlers[part.name]
        if (typeof handler === 'function') {
          handler(part)
        }
      }
    },
  }
}
